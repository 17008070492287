import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

const UtmParamPopout = (props) => {
    const { openUtmParamPopout, setOpenUtmParamPopout, utmParams } = props;
    const closeButtonRef = useRef(null);

    const trackingFieldMap = {
        '199': 'UTM Source',
        '200': 'UTM Medium',
        '201': 'UTM Campaign',
        '203': 'UTM Term',
        '202': 'Google Click ID',
        '204': 'Facebook Click ID',
        '205': 'Referral URL'
    };

    console.log(`UTM Params: ${JSON.stringify(utmParams)}`);

    return (
        <Transition show={openUtmParamPopout} as={Fragment}>
            <Dialog 
                as="div" 
                className="relative z-10" 
                onClose={() => setOpenUtmParamPopout(false)} 
                initialFocus={closeButtonRef}
            >
                {/* <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true" />
                </Transition.Child> */}

                {/* <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden"> */}
                        <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel 
                                    className="w-screen max-w-2xl"
                                    onClick={(event) => event.stopPropagation()}
                                >
                                    <div className="flex h-full flex-col bg-white py-6 shadow-xl overflow-hidden">
                                        <div className="px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-lg font-semibold leading-6 text-gray-900">
                                                    Tracking Sources
                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-rose-600 focus:ring-offset-2"
                                                        onClick={() => setOpenUtmParamPopout(false)}
                                                        ref={closeButtonRef}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative mt-6 flex-1 px-4 sm:px-6 overflow-y-auto" style={{ maxHeight: '80vh' }}>
                                            {utmParams.length === 0 && (
                                        <p className="text-center text-gray-500">No tracking collected.</p>
                                    )}
                                    {utmParams
                                        .filter(activity => activity.value) // Filter out activities with empty or falsy values
                                        .map((activity, index) => (
                                            <div key={index} className="mb-4 p-2 border-b border-gray-200">
                                                {/* <p><strong>Timestamp:</strong> {activity.cdate}</p> */}
                                                <p>
                                                    <strong>{trackingFieldMap[activity.field]}:</strong> {activity.value}
                                                </p>
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    {/* </div>
                </div> */}
            </Dialog>
        </Transition>
    );
};

export default UtmParamPopout;
