import React, { useState } from 'react';
import WebActivityPopout from './WebActivityPopout';
import UtmParamPopout from './UTMParamPopout';

const Details = ({ dealsData }) => {

    const [openWebActivityPopout, setOpenWebActivityPopout] = useState(false);
    const [openUtmParamPopout, setOpenUtmParamPopout] = useState(false);
    const [currentWebActivity, setCurrentWebActivity] = useState([]);
    const [currentUtmParams, setCurrentUtmParams] = useState([]);

    const handleWebActivityClick = (webActivity) => {
        setCurrentWebActivity(webActivity);
        setOpenWebActivityPopout(true);
    };

    const handleTrackingSourcesClick = (trackingSources) => {
        setCurrentUtmParams(trackingSources);
        setOpenUtmParamPopout(true);
    };

    const renderDeals = (deals) => {
        if (!Array.isArray(deals)) {
            return null;
        }

        return deals.map(deal => (
            <div key={deal.id} className="border-b border-gray-200 p-4">
                <a href={`${process.env.REACT_APP_AC_APP_URL}/deals/${deal.id}`} className="text-blue-500 hover:text-blue-800" target="_blank" rel="noopener noreferrer">Deal ID: {deal.id}</a>
                <p>Web Activity: {deal.webActivity} (# web pages visited)</p>
                <p>Referral Sources: {deal.referralFields?.map(field => field.value).join(', ')}</p>
                <p>Created Date: {deal.cdate}</p>
                <p>Won Date: {deal.wonDate || 'N/A'}</p>
            </div>
        ));
    };

    return (
        <div className="bg-white py-10">
            <h2 className="px-4 text-lg font-semibold leading-7 text-black sm:px-6 lg:px-8 text-left">Lead Details - Last 30 Days</h2>
            <table className="mt-6 w-full text-left">
                <colgroup>
                    <col className="w-full sm:w-3/12" />
                    <col className="lg:w-3/12" />
                    <col className="lg:w-1/12" />
                    <col className="lg:w-6/12" />
                    <col className="lg:w-1/12" />
                    <col className="lg:w-1/12" />
                    <col className="lg:w-1/12" />
                </colgroup>
                <thead className="border-b border-gray-200 text-sm leading-6 text-gray-700">
                    <tr>
                        <th scope="col" className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8">
                            Deal ID
                        </th>
                        <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:text-left lg:pr-8">
                            Status
                        </th>
                        <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:text-left lg:pr-8">
                            Pipeline
                        </th>
                        <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:text-left lg:pr-8">
                            Stage
                        </th>
                        <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell">
                            Referral Sources
                        </th>
                        <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-10">
                            Tracking Sources
                        </th>
                        <th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-10">
                            Web Activity
                        </th>
                        <th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold md:table-cell lg:pr-20">
                            Created Date
                        </th>
                        <th scope="col" className="hidden py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8">
                            Won Date
                        </th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-white/5">
                    {Object.keys(dealsData).flatMap(key =>
                        renderDeals(dealsData[key].deals) ? dealsData[key].deals.map(deal => (
                            <tr key={deal.id}>
                                {/* Deal ID */}
                                <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                                    <a href={`${process.env.REACT_APP_AC_APP_URL}/deals/${deal.id}`} className="text-blue-500 hover:text-blue-800" target="_blank" rel="noopener noreferrer">
                                        {deal.id}
                                    </a>
                                </td>
                                {/* Deal Status */}
                                <td className="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-10">
                                    <span className={`px-2 py-2 rounded text-white 
                                            ${key === 'won' ? 'bg-emerald-400 shadow-lg shadow-emerald-400/50' :
                                            key === 'open' ? 'bg-amber-400 shadow-lg shadow-amber-400/50' :
                                                key === 'lost' ? 'bg-red-400 shadow-lg shadow-red-400/50' : ''
                                        }`}>
                                        {key.charAt(0).toUpperCase() + key.slice(1)} {/* Capitalize the status */}
                                    </span>
                                </td>
                                {/* <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8 wrap-text">
                                    {deal.group}
                                </td> */}
                                {/* Pipline Column */}
                                <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8 wrap-text">
                                    {
                                        Array.isArray(dealsData.pipelines.dealGroups)
                                            ? (dealsData.pipelines.dealGroups.find(pipeline => (pipeline.id) === (deal.group))?.title || 'Unknown Pipeline')
                                            : 'Unknown Pipeline'
                                    }
                                </td>
                                {/* Stage Column */}
                                <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8 wrap-text">
                                    {
                                        Array.isArray(dealsData.pipelines.dealStages)
                                            ? (dealsData.pipelines.dealStages.find(stage => (stage.id) === (deal.stage))?.title || 'Unknown Stage')
                                            : 'Unknown Stage'
                                    }
                                </td>
                                {/* Referral Sources Column */}
                                <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8 wrap-text">
                                    {deal.referralFields?.map(field => field.value).join(', ')}
                                </td>
                                {/* Tracking Sources Column */}
                                <td className="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-10 text-center">
                                    {deal.trackingParamFields.some(field => field.value) ? ( // Check if any field has a value
                                        <button
                                            onClick={() =>
                                                handleTrackingSourcesClick(
                                                    deal.trackingParamFields.filter(field => field.value) // Pass only fields with values
                                                )
                                            }
                                            className="text-rose-600 hover:text-rose-800 focus:outline-none"
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                                padding: 0,
                                                textDecoration: 'none',
                                                cursor: 'pointer',
                                                fontSize: '15px',
                                            }}
                                        >
                                            {deal.trackingParamFields.filter(field => field.value).length} {/* Count fields with values */}
                                        </button>
                                    ) : (
                                        <span>0</span>
                                    )}
                                </td>
                                {/* Web Activity Column */}
                                <td className="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-10 text-center">
                                    {deal.webActivity.length > 0 ? (
                                        <button
                                            onClick={() => handleWebActivityClick(deal.webActivity)}
                                            className="text-rose-600 hover:text-rose-800 focus:outline-none"
                                            style={{ background: 'none', border: 'none', padding: 0, textDecoration: 'none', cursor: 'pointer', fontSize: '15px' }}
                                        >
                                            {deal.webActivity.length}
                                        </button>
                                    ) : (
                                        <span>{deal.webActivity.length}</span>
                                    )}
                                </td>
                                {/* Created Date Column */}
                                <td className="hidden py-4 pl-0 pr-8 text-sm leading-6 text-gray-700 md:table-cell lg:pr-20">
                                    {new Date(deal.cdate).toLocaleDateString()}
                                </td>
                                {/* Won Date Column */}
                                <td className="hidden py-4 pl-0 pr-4 text-right text-sm leading-6 text-gray-700 sm:table-cell sm:pr-6 lg:pr-8">
                                    {deal.wonDate ? new Date(deal.wonDate).toLocaleDateString() : 'N/A'}
                                </td>
                            </tr>
                        )) : null
                    )}
                </tbody>
            </table>
            <WebActivityPopout
                webActivity={currentWebActivity}
                openWebActivityPopout={openWebActivityPopout}
                setOpenWebActivityPopout={setOpenWebActivityPopout}
            />
            <UtmParamPopout
                utmParams={currentUtmParams}
                openUtmParamPopout={openUtmParamPopout}
                setOpenUtmParamPopout={setOpenUtmParamPopout}
            />
        </div>
    );
};

export default Details;